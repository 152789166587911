import React, { useEffect, useState } from 'react';

import jwtDecode from "jwt-decode";

//redux
import { useSelector, useDispatch } from "react-redux";
import { _setIdProfile } from '../actions/fermer-enregistrement';

//composant
import FermerEnregistrement from './connecte/fermer';
import RechercheEnregistrement from './non-connecte/chercher-enregistrement';

const VerifieAuthentification = () => {

    // State
    const authToken = useSelector(state => state.authToken);
    const [ernregistrementIsDne, setCheckEnregistrementIsDone] = useState(false);

    const dispatch = useDispatch();

    //useEffect
    useEffect(() => {

        // Verifie si l'utilisateur est connecte
        verifieExistenceToken()
    }, [])

    //functions
    const verifieExistenceToken = () => {

        if (authToken) {

            //recupere l'idProfile de l'utilisateur connecte
            let decodedToken = jwtDecode(authToken);
            dispatch(_setIdProfile(decodedToken.idProfile))

            // met a jour le state pour naviguer vers l'onglet de fermeture
            setCheckEnregistrementIsDone(true)
        } else {
            // met a jour le state pour rechercher un enregistrement
            setCheckEnregistrementIsDone(false)
        }
    }

    return (
        <>
            {ernregistrementIsDne ? (
                <FermerEnregistrement />
            ) : (
                <RechercheEnregistrement _setCheckEnregistrementIsDne={(value) => setCheckEnregistrementIsDone(value)} />
            )
            }
        </>
    )

}

export default VerifieAuthentification
