import React, { useEffect, useState } from 'react';

//Api
import { getEnregistrementList } from '../../../server/services/fermer-enregistrement';

//composant
import LayoutFermerEnregistrement from '../layout';
import TableauContent from './tableau-content';

const FermerEnregistrement = () => {

  const [enregistrementList, setEnregistrementList] = useState([])
  const [load, setLoad] = useState(false);

  //useEffect
  useEffect(() => {
    //optient la liste des enregistrements
  }, [])

  //funtions
  const getEnregistrement = async () => {
    const result = await getEnregistrementList()
    if(result) {
      setEnregistrementList(result);
      return true
    }
    return false
  }

  const fakeData = [
    { col1: 'PAV-7497', col2: '2020-05-13 14:53', col3: 'Fermer' },
    { col1: 'PAV-7497', col2: '2020-05-13 14:53', col3: 'Fermer' },
    { col1: 'PAV-7497', col2: '2020-05-13 14:53', col3: 'Fermer' },
    { col1: 'PAV-7497', col2: '2020-05-13 14:53', col3: 'Fermer' },
    { col1: 'PAV-7497', col2: '2020-05-13 14:53', col3: 'Fermer' },
  ];

  return (

    <LayoutFermerEnregistrement>
      <TableauContent data={fakeData} />
    </LayoutFermerEnregistrement>


  )

}

export default FermerEnregistrement
