import { api } from "../api";

export async function getEnregistrement(email, noEnregistrement) {
    // return api.get(``)
    return null
}

export async function getEnregistrementList(idProfile) {
    // return api.get(``)
    return null
}

export async function postFemerEnregistrement(data) {
    // return api.post(``)
    return null
}