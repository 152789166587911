import React, { Component } from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import { setAuthToken } from './actions/auth-token';
import { showOverlay, hideOverlay } from './actions/overlay';
import { showPopup, showYesNoPopup, hidePopup } from './actions/generic-popup';
import { setAlert } from './actions/alert';
import { SaveProfilClient } from './actions/infoClient'
import Calendar from "./calendar.js";
import { compareStringArrays } from "../utils/sorting";
import { get, post, patch } from '../server/api.js';
import jwtDecode from "jwt-decode";
import { isValidEmail, isValidPhoneNumber, isValidPostalCode, isEmptyObject } from "../utils/validation";
import { toDateString, toISODateTimeString, isUnformattedPhoneNumber, formatPhoneNumber } from "../utils/formatting";
import withNavigation from "./higher-order/navigation";
import { relations, relationsByIds } from "../enums/relations.js";
import { typesPermisPeche, typesPermisPecheByIds } from "../enums/types-permis-peche.js";
import EditSvg from "./svg/edit.js";
import DeleteSvg from "./svg/delete.js";
//import withTranslation from "./higher-order/translation";
import Translator from "./translator";
import "../../css/profile-page.css";
import { ID_PROVINCE_QUEBEC_CONFIG_WEB, ID_PAYS_CANADA_CONFIG_WEB } from "../constants.js";
import SessionKeys from "../session";
import { getPaysConfigWeb } from "./actions/pays-config-web";
import { getProvincesConfigWeb } from "./actions/provinces-config-web";
import { PATH_PAIEMENT, PATH_DROIT_ACCES, PATH_ACHAT_FORFAIT } from "../constants/navigation-constants";
import { STORAGE } from '../constants/storage.js'
import { setFromStorage, getFromStorage} from '../utils/localStorageUtils'
import { setSeoTitle } from "../utils/document";
import { getProfilClient } from "../server/services/configuration-service";


let erreurChampVide = "* Champ obligatoire";
const erreurCourrielInvalide = "* Ce courriel est invalide";
const erreurValeurInvalide = "* Valeur invalide";
const erreurMotDePasseCourt = "* Ce mot de passe est trop court";
const erreurMotsDePassesDifferents = "* Doit être identique à votre mot de passe";
const erreurMemeAdresseEnfants = "* Les enfants doivent résider à la même adresse que la personne principale";
const erreurMemeAdresseAdultes = "* Les adultes doivent résider à la même adresse que la personne principale";
const erreurMemeAdresseDependants = "* Les dépendants doivent résider à la même adresse que la personne principale";

const MONTRER_TOUT = 'Tout';
const MONTRER_DÉPENDANTS = 'Dépendants';
const MONTRER_VÉHICULES = 'Véhicules';

let tempId = 0;
function generateTempId() {
  return ++tempId;
}

function dependantMapper(d) { // Méthode permettant également d'initialiser un nouveau dépendant
  return {
    idDependant: d.idDependant || null,
    idProfile: d.idProfile || null,
    relation: d.relation || 0,
    nom: d.nom || "",
    prenom: d.prenom || "",
    telephone1: d.telephone1 || "",
    telephone2: d.telephone2 || "",
    portable: d.portable || "",
    email: d.email || "",
    idProvince: d.idProvince || ID_PROVINCE_QUEBEC_CONFIG_WEB,
    idPays: d.idPays || ID_PAYS_CANADA_CONFIG_WEB,
    adresse: d.adresse || "",
    ville: d.ville || "",
    codePostal: d.codePostal || "",
    dateNaissance: d.dateNaissance || toDateString(new Date()),
    permisPeche: d.permisPeche || "",
    permisPetitGibier: d.permisPetitGibier || "",
    typePermisPeche: d.typePermisPeche || -1,
    deleted: false,
  };
};

function vehiculeMapper(v) { // Méthode permettant également d'initialiser un nouveau véhicule
  return {
    idVehicule: v.idVehicule || null,
    idProfile: v.idProfile || null,
    description: (v.description || "").trim(), // On fait un .trim() car dans la BD, vehicule.description et vehicule.no_plaque sont des nchar
    noPlaque: (v.noPlaque || "").trim(), // On fait un .trim() car dans la BD, vehicule.description et vehicule.no_plaque sont des nchar
    type: v.type || "TE", // Options: TE-Terrestre, AE-Aérien, EM-Embarcation, RE-Remorque
    deleted: false,
  };
};

function dependantsSorter(a, b) {
  return compareStringArrays([a.nom.toUpperCase(), a.prenom.toUpperCase()], [b.nom.toUpperCase(), b.prenom.toUpperCase()]);
};

function vehiculesSorter(a, b) {
  compareStringArrays([a.description.toUpperCase(), a.noPlaque.toUpperCase()], [b.description.toUpperCase(), b.noPlaque.toUpperCase()]);
};

class ProfilePage extends Component {
  constructor(props) {
    super(props);

    this.mounted = false; // Pour corriger un memory leak

    this.onLoginKeyDown = this.onLoginKeyDown.bind(this)
    this.onSignupKeyDown = this.onSignupKeyDown.bind(this)

    this.state = {
      showCalendrierDateNaissance: false,
      showCalendrierDateNaissanceDependant: false,
      isCalendarBlurred: false,
      profile: {
        nom: "",
        prenom: "",
        idPays: "",
        idProvince: "",
        adresse: "",
        ville: "",
        codePostal: "",
        telephone1: "",
        telephone2: "",
        email: "",
        dateNaissance: "",
      },
      username: "",
      password: "",
      passwordConfirm: "",
      pays: [],
      provinces: [],
      dependants: [],
      vehicules: [],
      errors: {},
      hadDependants: undefined,
    };

    this.calendarDateNaissanceDependantWrapperDivRef = React.createRef();
    this.calendarDateNaissanceDependantInputRef = React.createRef();
  }
  TEXTS = Translator(this.props.match.params.language, "TXT_PROFILE_PAGE");

  componentDidMount() {
    setSeoTitle(this.props.entrepriseName, "Profile");
    this.mounted = true;

    this.tryGetProfile();
    this.tryGetDependants();
    this.tryGetVehicules();
    this.getPays();
    this.getProvinces();

    if (this.props.authToken == null) {
      if (this.props.history.location.pathname.includes("/profile/nouveau"))
        this.signupEmailInput.focus();
      else
        this.usernameInput.focus();
    }

    //setTimeout(() => {
    //  console.log(1);
    //  get("/" + this.props.idZec + '/Profile/TestPerf2', false, this.props.authToken).then(console.log);
    //}, 5000);
    //setTimeout(() => {
    //  console.log(2);
    //  get("/" + this.props.idZec + '/Profile/TestPerf1', false, this.props.authToken).then(console.log);
    //}, 20000);
    //setTimeout(() => {
    //  console.log(1);
    //  get("/" + this.props.idZec + '/Profile/TestPerf3', false, this.props.authToken).then(console.log);
    //}, 5000);
  }

  componentWillUnmount() {
    this.mounted = false;

    this.props.hideOverlay({ key: ["PROFILE_CONFIGURATION", "PROFILE_PARAMETRES_WEB", "PROFILE", "PROFILE_DEPENDANTS", "PROFILE_VEHICULES", "PROFILE_LOGIN", "PROFILE_SIGNUP", "PROFILE_SAVE"] });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.showCalendrierDateNaissance)
      ReactDOM.findDOMNode(this.refs.calendarDateNaissanceWrapperDiv).focus();

    if (this.state.showCalendrierDateNaissanceDependant && this.calendarDateNaissanceDependantWrapperDivRef.current !== null)
      this.calendarDateNaissanceDependantWrapperDivRef.current.focus();

    if (isEmptyObject(prevProps.configuration) && !isEmptyObject(this.props.configuration) && (this.props.overlay.showByKey || {}).PROFILE_CONFIGURATION !== undefined) // Configurations chargées. On cache l'overlay des configurations.
      this.props.hideOverlay("PROFILE_CONFIGURATION");

    if (isEmptyObject(this.props.configuration) && (this.props.overlay.showByKey || {}).PROFILE_CONFIGURATION === undefined) // Configurations non chargées. On affiche l'overlay des configurations.
      this.props.showOverlay("PROFILE_CONFIGURATION");

    if (isEmptyObject(prevProps.parametresWeb) && !isEmptyObject(this.props.parametresWeb) && (this.props.overlay.showByKey || {}).PROFILE_PARAMETRES_WEB !== undefined) // Paramètres webs chargés. On cache l'overlay des paramètres web.
      this.props.hideOverlay("PROFILE_PARAMETRES_WEB");

    if (isEmptyObject(this.props.parametresWeb) && (this.props.overlay.showByKey || {}).PROFILE_PARAMETRES_WEB === undefined) // Paramètres webs non chargés. On affiche l'overlay des paramètres web.
      this.props.showOverlay("PROFILE_PARAMETRES_WEB");
  }

  //#region DB gets/tryGets
  tryGetProfile() {
    if (this.props.authToken != null) {
      var decodedToken = jwtDecode(this.props.authToken);
      return this.getProfile(decodedToken.idProfile);
    }
  }

  tryGetDependants() {
    if (this.props.authToken != null) {
      var decodedToken = jwtDecode(this.props.authToken);

      this.getDependants(decodedToken.idProfile);
    }
  }

  tryGetVehicules() {
    if (this.props.authToken != null) {
      var decodedToken = jwtDecode(this.props.authToken);

      this.getVehicules(decodedToken.idProfile);
    }
  }

  getProfileDependantsVehicules(idProfile) {
    this.getProfile(idProfile);
    this.getDependants(idProfile);
    this.getVehicules(idProfile);
  }


  getProfile(idProfile) {
    this.props.showOverlay("PROFILE");

    return getProfilClient(this.props.idZec, idProfile, this.props.authToken).then(result => {
      if (result.status !== 200)
        throw new Error("Une erreur s'est produite et le profile n'a pas pu être chargé.");

      return result.json();
    }).then(profile => {
      this.mounted && this.setState({
        profile: {
          nom: profile.nom,
          prenom: profile.prenom,
          idPays: profile.idPays,
          idProvince: profile.idProvince,
          adresse: profile.adresse,
          ville: profile.ville,
          codePostal: profile.codePostal,
          telephone1: profile.telephone1,
          telephone2: profile.telephone2,
          email: profile.email,
          dateNaissance: profile.dateNaissance,
        }
      });
      
      // Sauvegarde du profil de l'utilisateur 
      if(setFromStorage(STORAGE.info_profile_client, profile)) {
        let dataProlieClient = getFromStorage(STORAGE.info_profile_client)
        if(dataProlieClient) this.props.SaveProfilClient(dataProlieClient) // sauvegarde dans Redux
       }

    }).catch(e => {
      console.log(e);
      this.props.setAlert(e.message, "danger");
    }).finally(() => {
      this.props.hideOverlay("PROFILE");
    });
  }

  getDependants(idProfile) {
    this.props.showOverlay("PROFILE_DEPENDANTS");

    return get("/" + this.props.idZec + '/Profile/' + idProfile + "/Dependant", false, this.props.authToken).then(result => {
      if (result.status !== 200)
        throw new Error("Une erreur s'est produite et les dépendants n'ont pas pu être chargés.");

      return result.json();
    }).then(dependants => {
      this.setState({
        dependants: dependants.map(dependantMapper).sort(dependantsSorter),
        hadDependants: dependants.length > 0,
      });
    }).catch(e => {
      console.log(e);
      this.props.setAlert(e.message, "danger");
    }).finally(() => {
      this.props.hideOverlay("PROFILE_DEPENDANTS");
    });
  }

  getVehicules(idProfile) {
    this.props.showOverlay("PROFILE_VEHICULES");

    return get("/" + this.props.idZec + '/Profile/' + idProfile + "/Vehicule", false, this.props.authToken).then(result => {
      if (result.status !== 200)
        throw new Error("Une erreur s'est produite et les véhicules n'ont pas pu être chargés.");

      return result.json();
    }).then(vehicules => {
      this.setState({
        vehicules: vehicules.map(vehiculeMapper).sort(vehiculesSorter),
      });
    }).catch(e => {
      console.log(e);
      this.props.setAlert(e.message, "danger");
    }).finally(() => {
      this.props.hideOverlay("PROFILE_VEHICULES");
    });
  }

  getPays() {
    return this.props.getPaysConfigWeb({ idZec: this.props.idZec }).then(({ pays }) => this.mounted && this.setState({
      pays: pays.filter(p => p.idPays !== '00000000-0000-0000-0000-000000000000'), // Ce filter enlève le pays Aucun
      profile: {
        ...this.state.profile,
        idPays: (this.state.profile.idPays || "") !== "" ? this.state.profile.idPays : (pays.find(p => p.description.toLowerCase() === 'canada').idPays) || "", // Set le idPays du Canada par défaut au profile si ce dernier n'a aucun idPays
      }
    })).catch(e => {
      console.log(e);
      this.props.setAlert("Une erreur s'est produite. Veuillez réessayer plus tard ou contacter l'administrateur si l'erreur persiste.", "danger");
    });
  }

  getProvinces() {
    return this.props.getProvincesConfigWeb({ idZec: this.props.idZec }).then(({ provinces }) => this.mounted && this.setState({
      provinces: provinces.filter(p => p.idProvince !== '00000000-0000-0000-0000-000000000000'), // Ce filter enlève la province Aucune
      profile: {
        ...this.state.profile,
        idProvince: (this.state.profile.idProvince || "") !== "" ? this.state.profile.idProvince : (provinces.find(p => p.code.toLowerCase() === 'qc').idProvince) || "", // Set le idProvince du Québec par défaut au profile si ce dernier n'a aucun idProvince
      }
    })).catch(e => {
      console.log(e);
      this.props.setAlert("Une erreur s'est produite. Veuillez réessayer plus tard ou contacter l'administrateur si l'erreur persiste.", "danger");
    });
  }
  //#endregion

  //#region validations
  validateAndLogin() {
    if (this.validateLogin())
      this.login();
  }

  validateAndSignup() {
    if (this.validateSignup())
      this.signup();
  }

  validateAndSave() {
    if (this.validateSave())
      this.save();
  }

  validateLogin() {
    this.state.errors.username = this.state.username.trim() === "" ? erreurChampVide : "";
    this.state.errors.password = this.state.password.trim() === "" ? erreurChampVide : "";

    this.setState({ ...this.state });

    const valid = this.state.errors.username === ""
      && this.state.errors.password === "";

    if (!valid)
      this.props.setAlert(`Assurez-vous tous les champs sont valides puis réessayez à nouveau.`, 'danger');

    return valid;
  }

  validateSignup() {
    const errors = { ...this.state.error };
    const countryCode = this.state.pays.find(p => p.idPays === this.state.profile.idPays).code.toUpperCase();

    errors.email = this.state.profile.email.trim() === "" ? erreurChampVide
      : !isValidEmail(this.state.profile.email.trim()) ? erreurCourrielInvalide
        : "";
    errors.password = this.state.password.trim() === "" ? erreurChampVide
      : this.state.password.trim().length < 8 ? erreurMotDePasseCourt
        : "";
    errors.passwordConfirm = this.state.passwordConfirm.trim() === "" ? erreurChampVide
      : this.state.password.trim() !== this.state.passwordConfirm.trim() ? erreurMotsDePassesDifferents
        : "";
    errors.nom = this.state.profile.nom.trim() === "" ? erreurChampVide : "";
    errors.prenom = this.state.profile.prenom.trim() === "" ? erreurChampVide : "";
    errors.adresse = this.state.profile.adresse.trim() === "" ? erreurChampVide : "";
    errors.ville = this.state.profile.ville.trim() === "" ? erreurChampVide : "";
    errors.codePostal = this.state.profile.codePostal.trim() === "" ? erreurChampVide
      : !isValidPostalCode(this.state.profile.codePostal.trim(), countryCode) ? erreurValeurInvalide
        : "";
    errors.telephone1 = this.state.profile.telephone1.trim() === "" ? erreurChampVide
      : !isValidPhoneNumber(this.state.profile.telephone1.trim()) ? erreurValeurInvalide
        : "";
    errors.telephone2 = this.state.profile.telephone2.trim() === "" ? ""
      : !isValidPhoneNumber(this.state.profile.telephone2.trim()) ? erreurValeurInvalide
        : "";
    errors.dateNaissance = this.state.profile.dateNaissance.trim() === "" ? erreurChampVide
      : isNaN(new Date(this.state.profile.dateNaissance).getTime()) ? erreurValeurInvalide
        : new Date(this.state.profile.dateNaissance.trim()) > new Date() ? erreurValeurInvalide
          : "";

    const valid = Object.values(errors).every(err => (err || "") === "");

    if (!valid)
      this.props.setAlert(`Assurez-vous tous les champs sont valides puis réessayez à nouveau.`, 'danger');

    this.setState({ errors: errors });

    return valid;
  }

  validateSave() {
    const errors = { ...this.state.error };
    const countryCode = this.state.pays.find(p => p.idPays === this.state.profile.idPays).code.toUpperCase();

    errors.nom = this.state.profile.nom.trim() === "" ? erreurChampVide : "";
    errors.prenom = this.state.profile.prenom.trim() === "" ? erreurChampVide : "";
    errors.adresse = this.state.profile.adresse.trim() === "" ? erreurChampVide : "";
    errors.ville = this.state.profile.ville.trim() === "" ? erreurChampVide : "";
    errors.codePostal = this.state.profile.codePostal.trim() === "" ? erreurChampVide
      : !isValidPostalCode(this.state.profile.codePostal.trim(), countryCode) ? erreurValeurInvalide
        : "";
    errors.telephone1 = this.state.profile.telephone1.trim() === "" ? erreurChampVide
      : !isValidPhoneNumber(this.state.profile.telephone1.trim()) ? erreurValeurInvalide
        : "";
    errors.telephone2 = this.state.profile.telephone2.trim() === "" ? ""
      : !isValidPhoneNumber(this.state.profile.telephone2.trim()) ? erreurValeurInvalide
        : "";
    errors.email = this.state.profile.email.trim() === "" ? erreurChampVide
      : !isValidEmail(this.state.profile.email.trim()) ? erreurCourrielInvalide
        : "";
    errors.dateNaissance = this.state.profile.dateNaissance.trim() === "" ? erreurChampVide
      : isNaN(new Date(this.state.profile.dateNaissance).getTime()) ? erreurValeurInvalide
        : new Date(this.state.profile.dateNaissance.trim()) > new Date() ? erreurValeurInvalide
          : "";

    const valid = Object.values(errors).every(err => (err || "") === "");

    if (!valid)
      this.props.setAlert(`Assurez-vous tous les champs sont valides puis réessayez à nouveau.`, 'danger');

    this.setState({ errors: errors });

    return valid;
  }

  validateDependant(dependant, callback) {
    const countryCode = this.state.pays.find(p => p.idPays === dependant.idPays).code.toUpperCase();

    const minDateAdult = new Date();
    minDateAdult.setFullYear(minDateAdult.getFullYear() - 18);

    const adultesMemeAdresseForfaitsFamiliaux = (this.props.configuration.adultesMemeAdresseForfaitsFamiliaux || "false").toUpperCase() === "TRUE";
    const enfantsMemeAdresseForfaitsFamiliaux = (this.props.configuration.enfantsMemeAdresseForfaitsFamiliaux || "false").toUpperCase() === "TRUE";

    const maybeAdulte = isNaN(new Date(dependant.dateNaissance).getTime()) || new Date(dependant.dateNaissance.trim()).getTime() >= minDateAdult.getTime();
    const maybeEnfant = isNaN(new Date(dependant.dateNaissance).getTime()) || new Date(dependant.dateNaissance.trim()).getTime() < minDateAdult.getTime();

    const hasMemeAdresse = dependant.adresse.trim() === this.state.profile.adresse.trim()
      && dependant.ville.trim() === this.state.profile.ville.trim()
      && dependant.codePostal.trim() === this.state.profile.codePostal.trim();

    const erreurMemeAdresse = hasMemeAdresse ? ""
      : adultesMemeAdresseForfaitsFamiliaux && enfantsMemeAdresseForfaitsFamiliaux ? erreurMemeAdresseDependants
        : adultesMemeAdresseForfaitsFamiliaux && maybeAdulte ? erreurMemeAdresseAdultes
          : enfantsMemeAdresseForfaitsFamiliaux && maybeEnfant ? erreurMemeAdresseEnfants
            : "";

    const errors = {
      ...this.state.error,
      dependantRelation: dependant.relation === 0 ? erreurChampVide : "",
      dependantNom: dependant.nom.trim() === "" ? erreurChampVide : "",
      dependantPrenom: dependant.prenom.trim() === "" ? erreurChampVide : "",
      dependantTelephone1: dependant.telephone1.trim() === "" ? erreurChampVide : !isValidPhoneNumber(dependant.telephone1.trim()) ? erreurValeurInvalide : "",
      dependantTelephone2: dependant.telephone2.trim() === "" ? "" : !isValidPhoneNumber(dependant.telephone2.trim()) ? erreurValeurInvalide : "",
      dependantEmail: dependant.email.trim() === "" ? "" : !isValidEmail(dependant.email.trim()) ? erreurCourrielInvalide : "",
      dependantPortable: dependant.portable.trim() === "" ? "" : !isValidPhoneNumber(dependant.portable.trim()) ? erreurValeurInvalide : "",
      dependantAdresse: dependant.adresse.trim() === "" ? erreurChampVide : erreurMemeAdresse,
      dependantVille: dependant.ville.trim() === "" ? erreurChampVide : erreurMemeAdresse,
      dependantCodePostal: dependant.codePostal.trim() === "" ? erreurChampVide : !isValidPostalCode(dependant.codePostal.trim(), countryCode) ? erreurValeurInvalide : erreurMemeAdresse,
      dependantDateNaissance: dependant.dateNaissance.trim() === "" ? erreurChampVide : isNaN(new Date(dependant.dateNaissance).getTime()) ? erreurValeurInvalide : new Date(dependant.dateNaissance.trim()) > new Date() ? erreurValeurInvalide : "",
    };

    const valid = Object.values(errors).every(err => (err || "") === "");

    if (!valid)
      this.props.setAlert(`Assurez-vous tous les champs sont valides puis réessayez à nouveau.`, 'danger');

    this.setState({ errors: errors }, () => callback(valid));
  }

  validateVehicule(vehicule, callback) {
    const errors = {
      ...this.state.error,
      vehiculeDescription: vehicule.description.trim() === "" ? erreurChampVide : "",
      vehiculeNoPlaque: vehicule.type === "TE" && vehicule.noPlaque.trim() === "" ? erreurChampVide : "",
    };

    const valid = (errors.vehiculeDescription || "") === ""
      && (errors.vehiculeNoPlaque || "") === "";

    if (!valid)
      this.props.setAlert(`Assurez-vous tous les champs sont valides puis réessayez à nouveau.`, 'danger');

    this.setState({ errors: errors }, () => callback(valid));
  }
  //#endregion

  //#region login/signup/save
  login() {
    this.props.showOverlay("PROFILE_LOGIN");

    return get("/" + this.props.idZec + '/Utilisateur/Auth?username=' + this.state.username + "&password=" + this.state.password, false).then(result => {
      if (result.status !== 200)
        throw new Error("Une erreur s'est produite. Veuillez réessayer plus tard ou contacter l'administrateur si l'erreur persiste.");

      return result.json();
    }).then(loginResponse => {
      const { idProfile } = jwtDecode(loginResponse.authToken);
      if (!loginResponse.success)
        throw new Error("Assurez-vous que le nom d'utilisateur et le mot de passe sont valide, puis réessayez à nouveau.");

      this.props.setAuthToken(loginResponse.authToken);
      sessionStorage.setItem(SessionKeys.AUTH_TOKEN, loginResponse.authToken);
      //save redux here
      this.getProfile(idProfile);

      if (!this.handleRedirect()) {

        this.getProfileDependantsVehicules(idProfile);
      }
    }).catch(e => {
      console.log(e);
      this.mounted && this.props.setAlert("Le nom d'utilisateur ou le mot de passe est invalide", "danger");
    }).finally(() => {
      this.props.hideOverlay("PROFILE_LOGIN");
    });
  }

  signup() {
    this.props.showOverlay("PROFILE_SIGNUP");

    return post("/" + this.props.idZec + '/Utilisateur/create', JSON.stringify({
      utilisateur: {
        username: this.state.profile.email.trim(),
        pass: this.state.password.trim(),
      },
      profile: {
        typePermisPeche: -1, // Important d'être avant "...this.state.profile". C'est parce que le type de permis de pêche est affiché seulement pour certaines zec. Pour ces zec, typePermisPeche viendra de this.state.profile, alors que pour les autres, typePermisPeche aura la valeur par défaut -1.
        ...this.state.profile,
        telephone1: this.state.profile.telephone1.replace(/\D/g, ''), // Enlève les non digit (\D),
        telephone2: this.state.profile.telephone2.replace(/\D/g, ''), // Enlève les non digit (\D),
        codePostal: this.state.profile.codePostal.replace(/[^a-zA-Z0-9]/g, ''), // Enlève les non alphanumerical
        derniereConnexion: toISODateTimeString(new Date(), false),
        dateCreation: toISODateTimeString(new Date(), false),
        dateModification: toISODateTimeString(new Date(), false),
        dateDerniereMaj: toISODateTimeString(new Date(), false),
      },
      vehicules: (this.state.vehicules || []).map(v => ({
        description: v.description.trim(),
        noPlaque: v.noPlaque.trim(),
        type: v.type,
        dateCreation: toISODateTimeString(new Date(), false)
      }
      )),
      dependants: (this.state.dependants || []).map(d => ({
        relation: d.relation,
        profile: {
          nom: d.nom.trim(),
          prenom: d.prenom.trim(),
          telephone1: d.telephone1.replace(/\D/g, ''), // Enlève les non digit (\D),
          telephone2: d.telephone2.replace(/\D/g, ''), // Enlève les non digit (\D),
          portable: d.portable.replace(/\D/g, ''), // Enlève les non digit (\D),
          email: d.email.trim(),
          idProvince: d.idProvince,
          idPays: d.idPays,
          adresse: d.adresse.trim(),
          ville: d.ville.trim(),
          codePostal: d.codePostal.replace(/[^a-zA-Z0-9]/g, ''), // Enlève les non alphanumerical
          dateNaissance: d.dateNaissance,
          permisPeche: d.permisPeche.trim(),
          permisPetitGibier: d.permisPetitGibier.trim(),
          typePermisPeche: d.typePermisPeche,
          dateCreation: toISODateTimeString(new Date(), false),
          dateModification: toISODateTimeString(new Date(), false),
          dateDerniereMaj: toISODateTimeString(new Date(), false),
        },
      })),
    }), {
      acceptLanguage: this.props.language,
    }).then(result => {
      if (result.status !== 200)
        throw new Error("Une erreur s'est produite. Veuillez réessayer plus tard ou contacter l'administrateur si l'erreur persiste.");

      return result.json();
    }).then(signupResult => {
      if (!signupResult.success)
        throw new Error(signupResult.error || "Une erreur s'est produite. Veuillez réessayer plus tard ou contacter l'administrateur si l'erreur persiste.");

      this.props.setAuthToken(signupResult.authToken);
      sessionStorage.setItem(SessionKeys.AUTH_TOKEN, signupResult.authToken);

      if (!this.handleRedirect()) {
        const { idProfile } = jwtDecode(signupResult.authToken);

        this.props.setAlert(`Le profile a été créé avec succès.`, 'success');

        this.getProfileDependantsVehicules(idProfile);
      }
    }).catch(e => {
      console.log(e);
      this.mounted && this.props.setAlert(e.message, "danger");
    }).finally(() => {
      this.props.hideOverlay("PROFILE_SIGNUP");
    });
  }

  save() {
    this.props.showOverlay("PROFILE_SAVE");

    const decodedToken = jwtDecode(this.props.authToken);
    const operations = [...this.getSaveOperationsProfile(), ...this.getSaveOperationsDependants(), ...this.getSaveOperationsVehicules()];

    patch("/" + this.props.idZec + '/Profile/' + decodedToken.idProfile, JSON.stringify(operations), this.props.authToken).then(res => {
      if (res.status !== 200)
        throw new Error("Une erreur s'est produite et la sauvegarde a échoué.");

      this.props.setAlert(`Le profile a été sauvegardé avec succès.`, 'success');

      if (!this.handleRedirect())
        this.getProfileDependantsVehicules(decodedToken.idProfile);
    }).catch(e => {
      console.log(e);
      this.props.setAlert(e.message, "danger");
    }).finally(() => {
      this.props.hideOverlay("PROFILE_SAVE");
    });
  }
  //#endregion

  //#region getSaveOperations
  getSaveOperationsProfile() {
    return [
      { op: "replace", path: "/nom", value: this.state.profile.nom.trim() },
      { op: "replace", path: "/prenom", value: this.state.profile.prenom.trim() },
      { op: "replace", path: "/adresse", value: this.state.profile.adresse.trim() },
      { op: "replace", path: "/ville", value: this.state.profile.ville.trim() },
      { op: "replace", path: "/email", value: this.state.profile.email.trim() },
      { op: "replace", path: "/dateNaissance", value: this.state.profile.dateNaissance },
      { op: "replace", path: "/idPays", value: this.state.profile.idPays },
      { op: "replace", path: "/idProvince", value: this.state.profile.idProvince },
      { op: "replace", path: "/codePostal", value: this.state.profile.codePostal.replace(/[^a-zA-Z0-9]/g, '') }, // Enlève les non alphanumerical
      { op: "replace", path: "/telephone1", value: this.state.profile.telephone1.replace(/\D/g, '') }, // Enlève les non digit (\D)
      { op: "replace", path: "/telephone2", value: this.state.profile.telephone2.replace(/\D/g, '') }, // Enlève les non digit (\D)
      { op: "replace", path: "/dateModification", value: toISODateTimeString(new Date(), false) },
      { op: "replace", path: "/dateDerniereMaj", value: toISODateTimeString(new Date(), false) },
    ];
  }

  getSaveOperationsDependants() {
    const decodedToken = jwtDecode(this.props.authToken);

    let countDeleted = 0;

    return this.state.dependants
      .concat()
      .sort((a, b) => { // On trie en mettant les profils existants (ne venant pas d'être créé) en premier, puis on trie par idDependant. Il est important que l'ordre des profils soit la même côté serveur pour que le PATCH fasse la MAJ correctement.
        if (Number.isInteger(a.idDependant) && !Number.isInteger(b.idDependant))
          return 1;

        if (!Number.isInteger(a.idDependant) && Number.isInteger(b.idDependant))
          return -1;

        return a.idDependant < b.idDependant ? -1 : 1;
      })
      .reduce((operations, dependant, i) => {
        const index = i - countDeleted;
        const isNew = Number.isInteger(dependant.idDependant); // Si Number.isInteger(dependant.idDependant), alors le dépendant a un id temporaire, ce qui signifie qu'il vient d'être créé.

        if (dependant.deleted) {
          if (!isNew) {
            operations.push({ op: 'test', path: `/dependants/${index}/idDependant`, value: dependant.idDependant }); // Ce test confirme que le service a les dépendants dans le même ordre, sinon quoi les autres opérations pourrait corrompre les données. Si ce test échoue, la MAJ entière échoue.
            operations.push({ op: 'remove', path: `/dependants/${index}` });
            countDeleted++;
          }
        }
        else if (isNew) {
          operations.push({
            op: 'add', path: `/dependants/-`, value: {
              relation: dependant.relation,
              idClientParent: decodedToken.idProfile,
              profile: {
                nom: dependant.nom.trim(),
                prenom: dependant.prenom.trim(),
                telephone1: dependant.telephone1.replace(/\D/g, ''),
                telephone2: dependant.telephone2.replace(/\D/g, ''),
                portable: dependant.portable.replace(/\D/g, ''),
                email: dependant.email.trim(),
                idProvince: dependant.idProvince,
                idPays: dependant.idPays,
                adresse: dependant.adresse.trim(),
                ville: dependant.ville.trim(),
                codePostal: dependant.codePostal.replace(/[^a-zA-Z0-9]/g, ''),
                dateNaissance: dependant.dateNaissance,
                permisPeche: dependant.permisPeche.trim(),
                permisPetitGibier: dependant.permisPetitGibier.trim(),
                typePermisPeche: dependant.typePermisPeche,
                dateModification: toISODateTimeString(new Date(), false),
                dateDerniereMaj: toISODateTimeString(new Date(), false),
              }
            }
          });
        }
        else {
          operations.push({ op: 'test', path: `/dependants/${index}/idDependant`, value: dependant.idDependant }); // Ce test confirme que le service a les dépendants dans le même ordre, sinon quoi les autres opérations pourrait corrompre les données. Si ce test échoue, la MAJ entière échoue.
          operations.push({ op: 'replace', path: `/dependants/${index}/relation`, value: dependant.relation });
          operations.push({ op: 'replace', path: `/dependants/${index}/profile/nom`, value: dependant.nom.trim() });
          operations.push({ op: 'replace', path: `/dependants/${index}/profile/prenom`, value: dependant.prenom.trim() });
          operations.push({ op: 'replace', path: `/dependants/${index}/profile/telephone1`, value: dependant.telephone1.replace(/\D/g, '') });
          operations.push({ op: 'replace', path: `/dependants/${index}/profile/telephone2`, value: dependant.telephone2.replace(/\D/g, '') });
          operations.push({ op: 'replace', path: `/dependants/${index}/profile/portable`, value: dependant.portable.replace(/\D/g, '') });
          operations.push({ op: 'replace', path: `/dependants/${index}/profile/email`, value: dependant.email.trim() });
          operations.push({ op: 'replace', path: `/dependants/${index}/profile/idProvince`, value: dependant.idProvince });
          operations.push({ op: 'replace', path: `/dependants/${index}/profile/idPays`, value: dependant.idPays });
          operations.push({ op: 'replace', path: `/dependants/${index}/profile/adresse`, value: dependant.adresse.trim() });
          operations.push({ op: 'replace', path: `/dependants/${index}/profile/ville`, value: dependant.ville.trim() });
          operations.push({ op: 'replace', path: `/dependants/${index}/profile/codePostal`, value: dependant.codePostal.replace(/[^a-zA-Z0-9]/g, '') });
          operations.push({ op: 'replace', path: `/dependants/${index}/profile/dateNaissance`, value: dependant.dateNaissance });
          operations.push({ op: 'replace', path: `/dependants/${index}/profile/permisPeche`, value: dependant.permisPeche.trim() });
          operations.push({ op: 'replace', path: `/dependants/${index}/profile/permisPetitGibier`, value: dependant.permisPetitGibier.trim() });
          operations.push({ op: 'replace', path: `/dependants/${index}/profile/typePermisPeche`, value: dependant.typePermisPeche });
          operations.push({ op: "replace", path: `/dependants/${index}/profile/dateModification`, value: toISODateTimeString(new Date(), false) });
          operations.push({ op: "replace", path: `/dependants/${index}/profile/dateDerniereMaj`, value: toISODateTimeString(new Date(), false) });
        }

        return operations;
      }, []);
  }

  getSaveOperationsVehicules() {
    const decodedToken = jwtDecode(this.props.authToken);

    let countDeleted = 0;

    return this.state.vehicules
      .concat()
      .sort((a, b) => { // On trie en mettant les véhicules existants (ne venant pas d'être créé) en premier, puis on trie par idVehicule. Il est important que l'ordre des véhicules soit la même côté serveur pour que le PATCH fasse la MAJ correctement.
        if (Number.isInteger(a.idVehicule) && !Number.isInteger(b.idVehicule))
          return 1;

        if (!Number.isInteger(a.idVehicule) && Number.isInteger(b.idVehicule))
          return -1;

        return a.idVehicule < b.idVehicule ? -1 : 1;
      })
      .reduce((operations, vehicule, i) => {
        const index = i - countDeleted;
        const isNew = Number.isInteger(vehicule.idVehicule); // Si Number.isInteger(vehicule.idVehicule), alors le véhicule a un id temporaire, ce qui signifie qu'il vient d'être créé.

        if (vehicule.deleted) {
          if (!isNew) {
            operations.push({ op: 'test', path: `/vehicules/${index}/idVehicule`, value: vehicule.idVehicule }); // Ce test confirme que le service a les véhicules dans le même ordre, sinon quoi les autres opérations pourrait corrompre les données. Si ce test échoue, la MAJ entière échoue.
            operations.push({ op: 'remove', path: `/vehicules/${index}` });
            countDeleted++;
          }
        }
        else if (isNew) {
          operations.push({
            op: 'add', path: `/vehicules/-`, value: {
              idProfile: decodedToken.idProfile,
              description: vehicule.description.trim(),
              noPlaque: vehicule.noPlaque.trim(),
              type: vehicule.type,
              dateCreation: toDateString(new Date()),
            }
          });
        }
        else {
          operations.push({ op: 'test', path: `/vehicules/${index}/idVehicule`, value: vehicule.idVehicule }); // Ce test confirme que le service a les véhicules dans le même ordre, sinon quoi les autres opérations pourrait corrompre les données. Si ce test échoue, la MAJ entière échoue.
          operations.push({ op: 'replace', path: `/vehicules/${index}/description`, value: vehicule.description.trim() });
          operations.push({ op: 'replace', path: `/vehicules/${index}/noPlaque`, value: vehicule.noPlaque.trim() });
          operations.push({ op: 'replace', path: `/vehicules/${index}/type`, value: vehicule.type });
        }

        return operations;
      }, []);
  }
  //#endregion

  handleRedirect() {
    const searchParams = new URLSearchParams(this.props.location.search);

    switch (searchParams.get("redirect")) {
      case "paiement": this.props.goTo(PATH_PAIEMENT); return true;
      case "droit-acces": this.props.goTo(PATH_DROIT_ACCES); return true;
      case "achat-forfait": this.props.goTo(PATH_ACHAT_FORFAIT); return true;
      default: return false;
    }
  }

  closeCalendrierDateNaissance() {
    this.setState({
      isCalendarBlurred: true,
      showCalendrierDateNaissance: false,
    });
  }

  toggleCalendrierDateNaissance() {
    this.setState({
      isCalendarBlurred: true,
      showCalendrierDateNaissance: !this.state.showCalendrierDateNaissance,
    });
  }

  toggleCalendrierDateNaissanceDependant(callback = () => { }) {
    this.setState({
      isCalendarBlurred: true,
      showCalendrierDateNaissanceDependant: !this.state.showCalendrierDateNaissanceDependant,
    }, callback);
  }

  onLoginKeyDown(e) {
    if (e.key === 'Enter')
      this.validateAndLogin();
  }

  onSignupKeyDown(e) {
    if (e.key === 'Enter')
      this.validateAndSignup();
  }

  onChangeProfilePropertyText(propertyName, newValue, maxLength, required) {
    if (newValue.length > maxLength)
      return;

    this.setState({
      profile: {
        ...this.state.profile, [propertyName]: newValue
      },
      errors: {
        ...this.state.errors,
        [propertyName]: required && newValue.length === 0 ? erreurChampVide : ""
      },
    });
  }

  onChangeEmail(newValue) {
    if (newValue.length > 60)
      return;

    this.setState({
      profile: {
        ...this.state.profile, email: newValue
      },
      errors: {
        ...this.state.errors,
        email: newValue.length === 0 ? erreurChampVide
          : !isValidEmail(newValue) ? erreurCourrielInvalide
            : ""
      },
    });
  }

  //#region showPopup
  showConfirmDeleteDependantPopup({ idDependant, nom, prenom }) {
    this.props.showYesNoPopup({
      title: "CONFIRMATION",
      bodyText: `Êtes-vous certain de vouloir supprimer ${prenom} ${nom} de vos dépendants?`,
      onYesClick: e => {
        this.setState({ dependants: this.state.dependants.map(d => d.idDependant === idDependant ? { ...d, deleted: true } : d) }, () => {
          this.props.hidePopup();
        });
      }
    });
  }

  showConfirmDeleteVehiculePopup({ idVehicule, description, noPlaque }) {
    this.props.showYesNoPopup({
      title: "CONFIRMATION",
      bodyText: `Êtes-vous certain de vouloir supprimer ${description} (${noPlaque}) de vos véhicules?`,
      onYesClick: e => {
        this.setState({ vehicules: this.state.vehicules.map(v => v.idVehicule === idVehicule ? { ...v, deleted: true } : v) }, () => {
          this.props.hidePopup();
        });
      }
    });
  }

  showModifyDependantPopup(dependant) {
    if (dependant === undefined)
      dependant = dependantMapper({}); // Initialise un dépendant (dependantMapper donne des valeurs par défaut aux champs manquants).

    const isAjout = dependant.idDependant === null;

    const styleDivGroups = { marginBottom: '16px', textAlign: 'left' };
    const styleInputs = { width: '100%' };
    const styleLabels = { textAlign: 'left' };

    const updateDependant = property => e => {
      dependant[property] = e.target.value;

      this.showModifyDependantPopup(dependant);
    };

    //const dateNaissanceInputRect = this.calendarDateNaissanceDependantInputRef.current === null ? {} : this.calendarDateNaissanceDependantInputRef.current.getBoundingClientRect();
    //const dateNaissanceInputBottom = this.calendarDateNaissanceDependantInputRef.current === null ? "0px" : (dateNaissanceInputRect.height + dateNaissanceInputRect.y) + 'px';

    const renderInput = (label, property, errorProp = null, input = <input type="text" />) => (
      <div style={styleDivGroups}>
        <div className="label" style={styleLabels}>{label}</div>
        {{ ...input, props: { style: styleInputs, value: dependant[property], onChange: updateDependant(property), ...input.props } }}
        {errorProp !== null && this.state.errors[errorProp] && <span className="color-error" style={{ fontFamily: 'Barlow-Bold', fontSize: '26px' }}>{this.state.errors[errorProp]}</span>}
      </div>
    );
    this.props.showPopup({
      title: isAjout ? "AJOUT DÉPENDANT" : "MODIFICATION DÉPENDANT",
      body: (
        <div style={{ position: 'relative' }}>
          {renderInput("RELATION :", "relation", 'dependantRelation',
            <select value={dependant.relation} onChange={updateDependant("relation")}>
              {relations.filter(r => [3, 7, 8, dependant.relation].includes(r.id)).map(r => ( // On n'affiche que conjoint, enfant, petit-enfant, et la relation actuelle si elle est différent de ces choix.
                <option key={r.id} value={r.id}>
                  {r.id === 0 ? "" : r.descriptionFr}
                </option>
              ))}
            </select>)
          }
          {renderInput("NOM :", "nom", 'dependantNom', <input type="text" disabled={!isAjout} />)}
          {renderInput("PRÉNOM :", "prenom", 'dependantPrenom', <input type="text" disabled={!isAjout} />)}
          {renderInput("COURRIEL :", "email", 'dependantEmail')}
          {renderInput("PAYS :", "idPays", null,
            <select>
              {(this.state.pays || []).map(pays => (
                <option key={pays.idPays} value={pays.idPays}>
                  {pays.description.toUpperCase()}
                </option>
              ))}
            </select>
          )}
          {renderInput("PROVINCE :", "idProvince", null,
            <select>
              {(this.state.provinces || []).filter(p => p.idPays === dependant.idPays).map(province => (
                <option key={province.idProvince} value={province.idProvince}>
                  {province.description.toUpperCase()}
                </option>
              ))}
            </select>
          )}
          {renderInput("CODE POSTAL :", "codePostal", 'dependantCodePostal')}
          {renderInput("VILLE :", "ville", 'dependantVille')}
          {renderInput("ADRESSE :", "adresse", 'dependantAdresse')}
          {renderInput("TÉLÉPHONE :", "telephone1", 'dependantTelephone1')}
          {renderInput("TÉLÉPHONE 2 :", "telephone2, 'dependantTelephone2'")}
          {renderInput("PORTABLE :", "portable", 'dependantPortable')}
          {renderInput("DATE DE NAISSANCE :", "dateNaissance", 'dependantDateNaissance',
            <input value={(dependant.dateNaissance || "").substring(0, 10)} ref={this.calendarDateNaissanceDependantInputRef} readOnly={true} className="clickable" onMouseDown={e => {
              this.toggleCalendrierDateNaissanceDependant(() => this.showModifyDependantPopup(dependant));
            }} />
          )}
          <div className="calendar-wrapper" ref={this.calendarDateNaissanceDependantWrapperDivRef} tabIndex={0} style={this.state.showCalendrierDateNaissanceDependant ? {} /*{position: 'fixed', top: dateNaissanceInputBottom}*/ : { display: "none" }}> {/* Avec {position: 'fixed', top: ... }, le calendrier apparaît toujours par dessus le popup, mais il faut également gérer le scroll pour faire suivre le calendrier. Je laisse tombé position fixed pour éviter de devoir gérer le scroll. */}
            <Calendar
              onDayClick={date => {
                if (date < new Date("1900-01-01 00:00:00.000") || date > new Date())
                  return;

                this.setState({
                  showCalendrierDateNaissanceDependant: false,
                }, () => {
                  dependant.dateNaissance = toDateString(date);

                  this.showModifyDependantPopup(dependant);
                })
              }}
              initialMonth={dependant.dateNaissance || new Date()}
              minDate={new Date("1900-01-01 00:00:00.000")}
              maxDate={new Date()}
              allowChangeYear={true}
            />
          </div>
          {/*<img src={require('../../images/icons/calendar-2.png')} alt="calendrier" className="clickable" onMouseDown={e => {
            this.toggleCalendrierDateNaissanceDependant(() => this.showModifyDependantPopup(dependant));
          }} />*/}
          {renderInput("PERMIS DE PÊCHE :", "permisPeche")}
          {renderInput("TYPE DE PERMIS DE PÊCHE :", "typePermisPeche", null,
            <select>
              {typesPermisPeche.filter(p => p.id !== -1 || dependant.typePermisPeche === -1).map(typePermisPeche => ( // Le filter fait en sorte que l'élément vide n'est affiché que lorsque le type de permis de pêche n'a pas encore été sélectionné
                <option key={typePermisPeche.id} value={typePermisPeche.id}>
                  {typePermisPeche.id === -1 ? "" : typePermisPeche.descriptionFr.toUpperCase()}
                </option>
              ))}
            </select>)}
          {renderInput("PERMIS DE PETIT GIBIER :", "permisPetitGibier")}
        </div>
      ),
      footer: (
        <div className="btn-span-wrapper" style={{ textAlign: 'center', marginTop: '0px' }} onClick={e => {
          this.validateDependant(dependant, valid => {
            if (valid) {
              this.setState({
                dependants: dependant.idDependant === null
                  ? [...this.state.dependants, { ...dependant, idDependant: generateTempId() }]
                  : this.state.dependants.map(d => d.idDependant === dependant.idDependant ? dependant : d)
              }, () => {
                this.props.hidePopup();
              });
            }
            else {
              this.showModifyDependantPopup(dependant); // Refresh
            }
          });
        }}>
          <span>CONFIRMER</span>
        </div>
      ),
    });
  }

  showModifyVehiculePopup(vehicule) {
    if (vehicule === undefined)
      vehicule = vehiculeMapper({}); // Initialise un véhicule (dependantMapper donne des valeurs par défaut aux champs manquants).

    const isAjout = vehicule.idVehicule === null;

    const styleDivGroups = { marginBottom: '16px', textAlign: 'left' };
    const styleInputs = { width: '100%' };
    const styleLabels = { textAlign: 'left' };

    const updateVehicule = property => e => {
      vehicule[property] = e.target.value;

      this.showModifyVehiculePopup(vehicule);
    };

    const renderInput = (label, property, errorProp = null, input = <input type="text" />) => (
      <div style={styleDivGroups}>
        <div className="label" style={styleLabels}>{label}</div>
        {{ ...input, props: { style: styleInputs, value: vehicule[property], onChange: updateVehicule(property), ...input.props } }}
        {errorProp !== null && this.state.errors[errorProp] && <span className="color-error" style={{ fontFamily: 'Barlow-Bold', fontSize: '26px' }}>{this.state.errors[errorProp]}</span>}
      </div>
    );

    this.props.showPopup({
      title: isAjout ? "AJOUT VÉHICULE" : "MODIFICATION VÉHICULE",
      body: (
        <div style={{ position: 'relative' }}>
          {renderInput("DESCRIPTION :", "description", 'vehiculeDescription', <input type="text" placeholder="Marque, modèle, couleur..." />)}
          {renderInput("PLAQUE :", "noPlaque", 'vehiculeNoPlaque')}
          {this.props.configuration.pavAfficherCaseTransportAerienVehicule === "True" &&
            <div style={styleDivGroups}>
              <div className="label" style={{ ...styleLabels, float: 'left' }}>AÉRIEN :</div>
              <input type="checkbox" style={{ float: 'left', marginLeft: '16px' }} checked={vehicule.type === "AE"} readOnly onClick={e => {
                vehicule.type = vehicule.type === "AE" ? "TE" : "AE";

                this.showModifyVehiculePopup(vehicule);
              }}
              />
            </div>
          }
        </div>
      ),
      footer: (
        <div className="btn-span-wrapper" style={{ textAlign: 'center', marginTop: '0px' }} onClick={e => {
          this.validateVehicule(vehicule, valid => {
            if (valid) {
              this.setState({
                vehicules: vehicule.idVehicule === null
                  ? [...this.state.vehicules, { ...vehicule, idVehicule: generateTempId() }]
                  : this.state.vehicules.map(v => v.idVehicule === vehicule.idVehicule ? vehicule : v)
              }, () => {
                this.props.hidePopup();
              });
            }
            else {
              this.showModifyVehiculePopup(vehicule); // Refresh
            }
          });
        }}>
          <span>CONFIRMER</span>
        </div>
      ),
    });
  }
  //#endregion

  renderChampsProfile() {
    return (
      <div>
        <div className="profile-div">
          <div className="profile-div profile-label-wrapper">
            <span className="profile-span">{this.TEXTS.title5} :</span>
          </div>
          <div className="profile-div input-container">
            <input type="text" value={this.state.profile.nom} onChange={e => this.onChangeProfilePropertyText("nom", e.target.value, 40, true)} className="profile-input" />
            {this.state.errors.nom && <span className="color-error profile-span">{this.state.errors.nom}</span>}
          </div>
        </div>
        <div className="profile-div">
          <div className="profile-div profile-label-wrapper">
            <span className="profile-span">{this.TEXTS.title6} :</span>
          </div>
          <div className="profile-div input-container">
            <input type="text" value={this.state.profile.prenom} onChange={e => this.onChangeProfilePropertyText("prenom", e.target.value, 40, true)} className="profile-input" />
            {this.state.errors.prenom && <span className="color-error profile-span">{this.state.errors.prenom}</span>}
          </div>
        </div>
        <div className="profile-div">
          <div className="profile-div profile-label-wrapper">
            <span className="profile-span">{this.TEXTS.title7} :</span>
          </div>
          <div className="profile-div input-container">
            <select value={this.state.profile.idPays} onChange={e => this.setState({ profile: { ...this.state.profile, idPays: e.target.value } })} className="profile-input">
              {(this.state.pays || []).map(pays => (
                <option key={pays.idPays} value={pays.idPays}>
                  {pays.description.toUpperCase()}
                </option>
              ))}
            </select>
            {this.state.errors.pays && <span className="color-error profile-span">{this.state.errors.pays}</span>}
          </div>
        </div>
        <div className="profile-div">
          <div className="profile-div profile-label-wrapper">
            <span className="profile-span">{this.TEXTS.title8} :</span>
          </div>
          <div className="profile-div input-container">
            <select value={this.state.profile.idProvince} onChange={e => this.setState({ profile: { ...this.state.profile, idProvince: e.target.value } })} className="profile-input">
              {(this.state.provinces || []).filter(p => p.idPays === this.state.profile.idPays).map(province => (
                <option key={province.idProvince} value={province.idProvince}>
                  {province.description.toUpperCase()}
                </option>
              ))}
            </select>
            {this.state.errors.province && <span className="color-error profile-span">{this.state.errors.province}</span>}
          </div>
        </div>
        <div className="profile-div">
          <div className="profile-div profile-label-wrapper">
            <span className="profile-span">{this.TEXTS.title9} :</span>
          </div>
          <div className="profile-div input-container">
            <input type="text" value={this.state.profile.adresse} onChange={e => this.onChangeProfilePropertyText("adresse", e.target.value, 100, true)} className="profile-input" />
            {this.state.errors.adresse && <span className="color-error profile-span">{this.state.errors.adresse}</span>}
          </div>
        </div>
        <div className="profile-div">
          <div className="profile-div profile-label-wrapper">
            <span className="profile-span">{this.TEXTS.title10} :</span>
          </div>
          <div className="profile-div input-container">
            <input type="text" value={this.state.profile.ville} onChange={e => this.onChangeProfilePropertyText("ville", e.target.value, 40, true)} className="profile-input" />
            {this.state.errors.ville && <span className="color-error profile-span">{this.state.errors.ville}</span>}
          </div>
        </div>
        <div className="profile-div">
          <div className="profile-div profile-label-wrapper">
            <span className="profile-span">{this.TEXTS.title11} :</span>
          </div>
          <div className="profile-div input-container">
            <input type="text" value={this.state.profile.codePostal} onChange={e => this.onChangeProfilePropertyText("codePostal", e.target.value, 9, true)} className="profile-input" />
            {this.state.errors.codePostal && <span className="color-error profile-span">{this.state.errors.codePostal}</span>}
          </div>
        </div>
        <div className="profile-div">
          <div className="profile-div profile-label-wrapper">
            <span className="profile-span">{this.TEXTS.title12} :</span> {/* NOTE: Messines veut ce terme (téléphone principal) */}
          </div>
          <div className="profile-div input-container">
            <input type="tel" value={this.state.profile.telephone1} onChange={e => this.onChangeProfilePropertyText("telephone1", e.target.value, 13, true)} className="profile-input" />
            {this.state.errors.telephone1 && <span className="color-error profile-span">{this.state.errors.telephone1}</span>}
          </div>
        </div>
        <div className="profile-div">
          <div className="profile-div profile-label-wrapper">
            <span className="profile-span">{this.TEXTS.title13} :</span> {/* NOTE: Messines veut ce terme (téléphone secondaire) */}
          </div>
          <div className="profile-div input-container">
            <input type="tel" value={this.state.profile.telephone2} onChange={e => this.onChangeProfilePropertyText("telephone2", e.target.value, 13, false)} className="profile-input" />
            {this.state.errors.telephone2 && <span className="color-error profile-span">{this.state.errors.telephone2}</span>}
          </div>
        </div>
        {this.props.authToken != null && // On n'affiche pas le courriel en mode signup du fait que comme les nouveaux profiles utilisent le courriel comme nom d'utilisateur, le champ courriel apparaît déjà en haut du formulaire
          <div className="profile-div">
            <div className="profile-div profile-label-wrapper">
              <span className="profile-span">{this.TEXTS.title3} :</span>
            </div>
            <div className="profile-div input-container">
              <input type="email" value={this.state.profile.email} onChange={e => this.onChangeEmail(e.target.value)} className="profile-input" />
              {this.state.errors.email && <span className="color-error profile-span">{this.state.errors.email}</span>}
            </div>
          </div>
        }
        <div className="profile-div">
          <div className="profile-div profile-label-wrapper">
            <span className="profile-span">{this.TEXTS.title14} :</span>
          </div>
          <div className="profile-div input-container">
            <input type="text" value={(this.state.profile.dateNaissance || "").substring(0, 10)} onChange={e => this.setState({ profile: { ...this.state.profile, dateNaissance: e.target.value } })} readOnly={true} className="profile-input clickable" onMouseDown={e => this.toggleCalendrierDateNaissance()} />
            {this.state.showCalendrierDateNaissance &&
              <div className="calendar-wrapper" ref="calendarDateNaissanceWrapperDiv" tabIndex={0} onBlur={e => {
                // Désactivé. Est incomptable avec Calendar.props.allowChangeYear.
                /*if (!this.state.isCalendarBlurred)
                  this.setState({ showCalendrierDateNaissance: false })
                else
                  this.setState({ isCalendarBlurred: false });*/
              }}>
                <Calendar
                  onDayClick={date => {
                    if (date < new Date("1900-01-01 00:00:00.000") || date > new Date())
                      return;

                    this.setState({
                      profile: {
                        ...this.state.profile,
                        dateNaissance: toDateString(date)
                      },
                      showCalendrierDateNaissance: false,
                    })
                  }
                  }
                  initialMonth={this.state.profile.dateNaissance || new Date()}
                  minDate={new Date("1900-01-01 00:00:00.000")}
                  maxDate={new Date()}
                  allowChangeYear={true}
                />
              </div>
            }
            {this.state.errors.dateNaissance && <span className="color-error profile-span">{this.state.errors.dateNaissance}</span>}
          </div>
          <img src={require('../../images/icons/calendar-2.png')} alt="calendrier" onMouseDown={e => this.toggleCalendrierDateNaissance()} className="profile-img" />
        </div>
      </div>
    )
  }

  renderDependants() {
    const canAddDeleteModifyDependants = this.state.hadDependants === false || (this.props.configuration.empecherModificationDependants || 'false').toUpperCase() === 'FALSE';

    return (
      <div>
        <div className="profile-div input-container">
          <span className="profile-span">CONJOINT / DÉPENDANTS</span>
          <div className="profile-div">
            <div className="profile-div profile-label-wrapper">
              <span className="profile-span"></span>
            </div>
            <table>
              <tbody>
                {this.state.dependants.filter(d => !d.deleted).map(d => (
                  <tr key={d.idDependant}>
                    <td>
                      <span className="profile-span">{d.nom} {d.prenom} {d.relation === 0 ? "" : "(" + relationsByIds[d.relation].descriptionFr + ")"}</span>
                    </td>
                    {canAddDeleteModifyDependants &&
                      <td>
                        <EditSvg className="clickable primary-color-on-hover" onClick={e => {
                          this.setState({ errors: {} }, () => {
                            this.closeCalendrierDateNaissance();
                            this.showModifyDependantPopup(d);
                          });
                        }} />
                      </td>
                    }
                    {canAddDeleteModifyDependants &&
                      <td>
                        <DeleteSvg className="clickable primary-color-on-hover" onClick={e => {
                          this.closeCalendrierDateNaissance();
                          this.showConfirmDeleteDependantPopup(d);
                        }} />
                      </td>
                    }
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="profile-div">
            <div className="profile-div profile-label-wrapper">
              <span className="profile-span"></span>
            </div>
            <div style={{ display: 'grid', padding: '8px' }}>
              {canAddDeleteModifyDependants
                ? <span className="label primary clickable" style={{ textTransform: 'initial' }} onClick={e => {
                  this.setState({ errors: {} }, () => {
                    this.closeCalendrierDateNaissance();
                    this.showModifyDependantPopup();
                  });
                }}>Cliquez ici pour ajouter un dépendant</span>
                : <span className="label primary" style={{ textTransform: 'initial' }}>{`Pour modifier vos dépendants, contacter la zec au ${this.props.entrepriseContact}.`}</span>
              }
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderVehicules() {
    return (
      <div>
        <div className="profile-div input-container">
          <span className="profile-span">VÉHICULES</span>
          <div className="profile-div">
            <div className="profile-div profile-label-wrapper">
              <span className="profile-span"></span>
            </div>
            <table>
              <tbody>
                {this.state.vehicules.filter(v => !v.deleted && (v.type === 'TE' || v.type === 'AE')).map(v => (
                  <tr key={v.idVehicule}>
                    <td>
                      {v.type === 'TE' && <span className="profile-span">{v.description} ({v.noPlaque})</span>}
                      {v.type === 'AE' && <span className="profile-span">{v.description}</span>}
                    </td>
                    <td>
                      <EditSvg className="clickable primary-color-on-hover" onClick={e => {
                        this.setState({ errors: {} }, () => {
                          this.closeCalendrierDateNaissance();
                          this.showModifyVehiculePopup(v);
                        });
                      }} />
                    </td>
                    <td>
                      <DeleteSvg className="clickable primary-color-on-hover" onClick={e => {
                        this.closeCalendrierDateNaissance();
                        this.showConfirmDeleteVehiculePopup(v);
                      }} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="profile-div">
            <div className="profile-div profile-label-wrapper">
              <span className="profile-span"></span>
            </div>
            <div style={{ display: 'grid', padding: '8px' }}>
              <span className="label primary clickable" style={{ textTransform: 'initial' }} onClick={e => {
                this.setState({ errors: {} }, () => {
                  this.closeCalendrierDateNaissance();
                  this.showModifyVehiculePopup();
                });
              }}>Cliquez ici pour ajouter un véhicule</span>
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderEmbarcations() {

  }

  renderRemorques() {

  }

  render() {
    const searchParams = new URLSearchParams(this.props.location.search);

    if (this.props.authToken != null) {
      // Utilisateur authentifié. On affiche le profile.
      return (
        <div id="profile-page" className="profile-div">
          <div style={{ display: 'flow-root' }}>
            {this.renderChampsProfile()}
            <div className="profile-div profile-separator" />
            {this.renderDependants()}
            <div className="profile-div profile-separator" />
            {this.renderVehicules()}
            {this.props.parametresWeb.stationDeLavage && <div className="profile-div profile-separator" />}
            {this.props.parametresWeb.stationDeLavage && this.renderEmbarcations()}
            {this.props.parametresWeb.stationDeLavage && <div className="profile-div profile-separator" />}
            {this.props.parametresWeb.stationDeLavage && this.renderRemorques()}
            {this.state.pays.length > 0 && this.state.provinces.length > 0 &&
              <div id="profile-save-wrapper" className="profile-div clickable" onClick={e => this.validateAndSave()}>
                <span className="profile-span">SAUVEGARDER</span>
              </div>
            }
          </div>
        </div>
      );
    };

    if (this.props.history.location.pathname.includes("/profile/nouveau")) {
      // Mode création de compte. On affiche les champs pour créer un compte.
      return (
        <div id="profile-page" className="profile-div">
          <div className="display-inline-block">
            <div className="profile-div">
              <div className="profile-div profile-label-wrapper">
                <span className="profile-span">{this.TEXTS.title3} :</span>
              </div>
              <div className="profile-div input-container">
                <input ref={input => this.signupEmailInput = input} type="text" value={this.state.profile.email} onChange={e => this.onChangeEmail(e.target.value)} onKeyDown={this.onSignupKeyDown} className="profile-input" />
                {this.state.errors.email && <span className="color-error profile-span">{this.state.errors.email}</span>}
              </div>
            </div>
            <div className="profile-div">
              <div className="profile-div profile-label-wrapper">
                <span className="profile-span">{this.TEXTS.title1} :</span>
              </div>
              <div className="profile-div input-container">
                <input type="password" value={this.state.password} onChange={e => this.setState({ password: e.target.value })} onKeyDown={this.onSignupKeyDown} className="profile-input" />
                {this.state.errors.password && <span className="color-error profile-span">{this.state.errors.password}</span>}
              </div>
            </div>
            <div className="profile-div">
              <div className="profile-div profile-label-wrapper">
                <span className="profile-span">{this.TEXTS.title4} :</span>
              </div>
              <div className="profile-div input-container">
                <input type="password" value={this.state.passwordConfirm} onChange={e => this.setState({ passwordConfirm: e.target.value })} onKeyDown={this.onSignupKeyDown} className="profile-input" />
                {this.state.errors.passwordConfirm && <span className="color-error profile-span">{this.state.errors.passwordConfirm}</span>}
              </div>
            </div>
            <div className="profile-div profile-separator" />
            {this.renderChampsProfile()}
            {this.state.pays.length > 0 && this.state.provinces.length > 0 &&
              <div className="profile-div">
                <div className="profile-div btn-login clickable" onClick={e => this.validateAndSignup()}>
                  <span className="profile-span">{this.TEXTS.title15}</span>
                </div>
              </div>
            }
            <div className="profile-div" style={{ textAlign: "center" }}>
              <span className="clickable primary-color message profile-span" onClick={e => this.props.gotoProfile(searchParams.get("redirect"))}>{this.TEXTS.text2}</span>
            </div>
          </div>
        </div>
      );
    }

    return (
      // Mode login. On affiche les champs pour se connecter.
      <div id="profile-page">
        <div className="display-inline-block">
          <div>
            <div className="profile-label-wrapper">
              <span className="profile-span">{this.TEXTS.title0} :</span>
            </div>
            <div className="input-container">
              <input ref={input => this.usernameInput = input} type="text" value={this.state.username} onChange={e => this.setState({ username: e.target.value })} onKeyDown={this.onLoginKeyDown} className="profile-input" />
              {this.state.errors.username && <span className="color-error profile-span">{this.state.errors.username}</span>}
            </div>
          </div>
          <div>
            <div className="profile-label-wrapper">
              <span className="profile-span">{this.TEXTS.title1} :</span>
            </div>
            <div className="input-container">
              <input type="password" value={this.state.password} onChange={e => this.setState({ password: e.target.value })} onKeyDown={this.onLoginKeyDown} className="profile-input" />
              {this.state.errors.password && <span className="color-error profile-span">{this.state.errors.password}</span>}
            </div>
          </div>
          <div className="profile-div">
            <div className="profile-div btn-login clickable" onClick={e => this.validateAndLogin()}>
              <span className="profile-span">{this.TEXTS.title2}</span>
            </div>
          </div>
          <div className="profile-div" style={{ textAlign: "center" }}>
            <span className="clickable primary-color message profile-span" onClick={e => this.props.gotoCreerCompte(searchParams.get("redirect"))}>{this.TEXTS.text0}</span>
          </div>
          <div className="profile-div" style={{ textAlign: "center" }}>
            <span className="clickable primary-color message profile-span" onClick={e => console.log("TODO: pw oublié")}>{this.TEXTS.text1}</span>
          </div>
        </div>

      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    authToken: state.authToken,
    parametresWeb: state.parametresWeb,
    configuration: state.configuration,
    overlay: state.overlay,
    entrepriseName: state.entreprise.nom,
    entrepriseContact: isUnformattedPhoneNumber(state.entreprise.contact) ? formatPhoneNumber(state.entreprise.contact) : state.entreprise.contact, // state.entreprise.contact peut être un courriel ou un numéro de téléphone
  };
}

export default withNavigation(connect(mapStateToProps, { getProvincesConfigWeb, getPaysConfigWeb, setAuthToken, setAlert, showOverlay, hideOverlay, showPopup, showYesNoPopup, hidePopup, SaveProfilClient })(ProfilePage));