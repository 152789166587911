import React, { useState, useContext } from 'react';
import { EnregistrementContext } from './context';

const TimeInput = ({ onChange }) => {
    const [hours, setHours] = useState(new Date().getHours());
    const [minutes, setMinutes] = useState(new Date().getMinutes());

    const {
        handleTimeChange,
    } = useContext(EnregistrementContext)

    const handleHoursChange = (increment) => {
        let newHours = hours + increment;
        if (newHours > 23) newHours = 0;
        else if (newHours < 0) newHours = 23;
        setHours(newHours);
        handleTimeChange(newHours, minutes);
    };

    const handleMinutesChange = (increment) => {
        let newMinutes = minutes + increment;
        if (newMinutes > 59) {
            newMinutes = 0;
            handleHoursChange(1);
        } else if (newMinutes < 0) {
            newMinutes = 59;
            handleHoursChange(-1);
        }
        setMinutes(newMinutes);
        handleTimeChange(hours, newMinutes);
    };

    return (
        <div className='container-time-input'>
            <div class="sub-title-section"><span class="droit-acces-span">Quelle heure était-il?</span></div>
        <div style={{ display: 'flex', alignItems: 'center', width: '12%' }}>
            <div className='content-input-heure'>
                <div className='content-increment'>
                    <button onClick={() => handleHoursChange(1)}>▲</button>

                </div>
                <input
                        type="text"
                        value={hours < 10 ? `0${hours}` : hours}
                        readOnly
                    />
                <div className='content-decrement'>
                    <button onClick={() => handleHoursChange(-1)}>▼</button>
                </div>
            </div>
            <span>:</span>
            <div className='content-input-minutes'>
                <div className='content-increment'>
                    <button onClick={() => handleMinutesChange(1)}>▲</button>
                </div>
                <div>
                    <input
                        type="text"
                        value={minutes < 10 ? `0${minutes}` : minutes}
                        readOnly
                    />
                </div>
                <div className='content-decrement'>
                    <button onClick={() => handleMinutesChange(-1)}>▼</button>
                </div>
            </div>
        </div>
        </div>
    );
};

export default TimeInput;
