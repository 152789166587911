import React, { useContext } from 'react';

//components
import TimeInput from './time-input.js';
import LayoutFermerEnregistrement from '../layout.js';
import withNavigation from "../../higher-order/navigation.js";
import CustomInputWithCalendar from './custum-input-calendrier.js';

//redux
import { setAlert } from '../../actions/alert';
import { useDispatch, useSelector } from "react-redux";

//context
import { EnregistrementContext } from './context.js';

//constante
import { PATH_FERMER_ENREGISTREMENT, PATH_RESERVATION } from '../../../constants/navigation-constants.js';

const RenderContentFermerCapture = ({goTo}) => {

    //State
    const dispatch = useDispatch();
    const authToken = useSelector(state => state.authToken);

    // contexte
    const {
        dateIsSelected,
        heureIsSlected,
        _fermerEnregistrement
    } = useContext(EnregistrementContext)

    const navigation = async () => {

        //verifie si l'utilisateur est toujours connecte
        if (authToken) {
            const result = await _fermerEnregistrement();
            if (result) {
                //la fermeture a réussi
                goTo(PATH_RESERVATION)
            } else {
                //la fermeture n'a pas réussi
                dispatch(setAlert(`Veuillez réessayer. Quelque chose a mal tourné.`, 'danger'));
            }
        } else {
            goTo(PATH_FERMER_ENREGISTREMENT)
        }
    }

    return (
        <LayoutFermerEnregistrement custmeClass={'fermer-enregistrement-capture-container'}>
            <div className='fermer-enregistrement-content'>
                {/* titre */}
                <div class="title-section"><span class="droit-acces-span">Fermeture enregistrement et captures</span></div>

                <div className="content-body">
                    {/* Date */}
                    <CustomInputWithCalendar />

                    {/* heure */}
                    {dateIsSelected &&
                        <TimeInput />
                    }

                    {/* button */}
                    {heureIsSlected &&
                        <button
                            onClick={() =>  navigation ()}
                            className="fermer-enregistrement-button"><span class="droit-acces-span">Fermer enregistrement</span>
                        </button>
                    }

                </div>

            </div>
        </LayoutFermerEnregistrement>
    )
}

export default withNavigation(RenderContentFermerCapture);