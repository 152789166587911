import React, { createContext, useEffect, useState } from "react";

//Api
import { postFemerEnregistrement } from '../../../server/services/fermer-enregistrement';

//redux
import { useSelector, useDispatch } from "react-redux";

// context
export const EnregistrementContext = createContext({});

//context provider 
export const EnregistrementContextProviderConnecte = ({children}) => {
    
    // States
    const [heure, setHeure] = useState('');
    const [minutes, setMinutes] = useState('');
    const [date, setDate] = useState(new Date());
    const [formStep, setFormStep ] = useState(1);
    const [dateIsSelected, setdateIsSelected] = useState(false);
    const [heureIsSlected, setHeureIsSlected] = useState(false);
    const [isOpenCalendar, setIsOpenCalendar] = useState(false);

    const idProfile = useSelector(state => state.fermerEnregistrement.idProfile);

    // functions
    const calendarClicked = (date) => {
        setDate(date);
        setdateIsSelected(true)
        setIsOpenCalendar(false)
    }

    const handleTimeChange = (heure, minutes) => {
        setHeure(heure)
        setMinutes(minutes)
        setHeureIsSlected(true)
        // console.log(' heure:', hours, minutes);
    };

     const fermerEnregistrement = async () => {
        const result = await postFemerEnregistrement({idProfile, date, heure, minutes});
        if(result) {
            //next
            return true;
        }
        return false;
     }

    const contextData = {
        // data
        date,
        formStep,
        heureIsSlected,
        isOpenCalendar,
        dateIsSelected,

        // methods
        setDate,
        setFormStep,
        calendarClicked,
        handleTimeChange,
        setdateIsSelected,
        setIsOpenCalendar,
        _fermerEnregistrement : fermerEnregistrement
    }


    return (
        <EnregistrementContext.Provider value={contextData}>
           {children}
        </EnregistrementContext.Provider>
    )
}

