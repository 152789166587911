import React from 'react';

const LayoutContent = (props) => {
    const { 
        texts,
        children,
        isActive,
        prixBase,
        className,
        onClickAdd,
        classModifier,
        onClickToggle,
        typeHebergement,
        showAddToShoppingCart,
        onClickAjouterPanier,
        typePeriode
    } = props

    const formatAffichagePrix = (prixBase, typePeriode) => {
        
        let affichagePrix = "-";
        if (prixBase !== 0) {
          if (typePeriode === 1) {
            affichagePrix = (prixBase || 0).toFixed(2) + " $ / heure";
          } else {
            affichagePrix = (prixBase || 0) + " $";
          }
        }
        return affichagePrix;
      }
    
    return (
        <div className={"unite-card " + classModifier} style={{ marginBlockEnd: props.isActivite && '0px' }}>
            {children}

            {/* Section consulter */}
            {
                <div className={"unite-card-consulter-wrapper " + (className ? className : classModifier)}>
                    <div className={"unite-card-a-partir-de" + (className ? className : classModifier)}>
                        <span>{texts?.title0}</span>
                    </div>
                    <div className="unite-card-montant">
                       <span>{formatAffichagePrix(prixBase, typePeriode)}</span> 
                    </div>
                    <div className="unite-card-consulter" onClick={() => onClickToggle()} >
                        <span>{texts?.title1}</span>
                    </div>
                    {(prixBase || 0) !== 0 && typeHebergement !== "AC" && onClickAjouterPanier && showAddToShoppingCart && (
                        <div className="unite-card-consulter" onClick={(e) => onClickAdd()}>
                            <span>{texts.title2}</span>
                        </div>
                    )}
                </div>
            }
        </div>
    )
}

export default LayoutContent;
