import React from 'react';

// component
import RenderContent from './render-content-fermer-capture.js';

//context
import { EnregistrementContextProviderConnecte } from './context.js';


const FermerEnregistrementEtCapture = ({ goTo }) => {

    return (
        <EnregistrementContextProviderConnecte>
            <RenderContent/>
        </EnregistrementContextProviderConnecte>
    )

}

export default FermerEnregistrementEtCapture;