import { SET_ID_PROFILE_ENREGISTREMET } from "../components/actions/types";

const initialState = { idProfile:'' };

export default function (state = initialState, action) {
    if (action.type === SET_ID_PROFILE_ENREGISTREMET) {
        return {
            ...state,
            idProfile: action.value,
        }
    }
    return state;
}