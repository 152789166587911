import React from 'react';

//composant
import withNavigation from "../../higher-order/navigation";
import { PATH_FERMER_ENREGISTREMENT_CAPTURE } from '../../../constants/navigation-constants';

//redux
import { setAlert } from '../../actions/alert';
import { useDispatch, useSelector } from "react-redux";


const TableauContent = ({ data, goTo }) => {
    // State
    const authToken = useSelector(state => state.authToken);
    const dispatch = useDispatch()

    const navigation = () => {
        if(authToken) {
            goTo(PATH_FERMER_ENREGISTREMENT_CAPTURE);
        } else {
            window.location.reload()
            dispatch(setAlert(`Vous n'êtes plus connecté.`, 'danger'));
        }
    }; 

    return (
        <div className='contenair-table'>
            <div class="title-section"><span class="droit-acces-span">Liste des enregistrements ouverts</span></div>
        <table className="table">
            <thead>
                <tr>
                    <th>Numéro</th>
                    <th>Date d'arrivée</th>
                    <th>Fermer</th>
                </tr>
            </thead>
            <tbody>
                {data.map((item, index) => (
                    <tr key={index}>
                        <td>{item.col1}</td>
                        <td>{item.col2}</td>
                        <td
                            className="clickable"
                            onClick={() => navigation()}
                        >
                            {item.col3}
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
        </div>
    );
};

export default withNavigation(TableauContent);